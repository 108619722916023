/* index.css */

html, body, #root {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Override the default shadow of the Mapbox Geocoder container */
.mapboxgl-ctrl-geocoder {
  box-shadow: none !important;
  border: 1px solid #ccc;
}
