.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bounding-box {
  position: absolute;
  border: 2px dashed #000;
  background-color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
}

/* legendcard css */

.card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
}

.custom-h5 {
  font-size: 16px;
  margin-bottom: 10px;
}

.dropdown {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.legend {
  display: flex;
  flex-direction: column;
}

.legend-item2 {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.color-swatch2 {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.value-input {
  width: 60px;
  padding: 5px;
  margin-left: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* App.css */
.chart-area {
  width: 100%;
  height: 100%;
}

.bounding-box {
  border: 2px dashed #0074D9;
  background-color: rgba(0, 116, 217, 0.1);
  position: absolute;
  pointer-events: none;
  z-index: 1000; /* Ensure it appears on top of the map */
}
